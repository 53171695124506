<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.recipe.edit.title' | translate }}
    v-card-content
      table.table
        thead.table__header
          tr.table__row.table__row--header
            td.table__cell {{ 'base.name' | translate }}
            td.table__cell {{ 'base.premix_base' | translate }}
            td.table__cell {{ 'base.composite_premixes' | translate }}
            td.table__cell {{ 'base.percent' | translate }}
            td.table__cell {{ 'base.order_queue' | translate }}
        tbody.table__body
          tr.table__row.table__row--body.table__row--no-hover(
            v-for="item in items")
            td.table__cell.word-break {{ item.ingredient_name }}
            td.table__cell
              v-checkbox(
               :disabled="isUserConsultant"
               v-model="item.premix"
               @input="onChangePremix(item, $event)")
            td.table__cell
              v-checkbox(
                :disabled="isUserConsultant"
                v-model="item.oil")
            td.table__cell
              v-editable(
                @change="(value) => { item.rate = value }"
                :disabled="isUserConsultant")
                span {{ item.rate }}
            td.table__cell {{ item.order }}
    v-card-actions
      v-btn.mr-2(
        :disabled="!isPercentValid"
        @click="onSave") {{ 'actions.close' | translate }}
      template(v-if="!isUserConsultant")
        v-btn(@click="showCloneRecipeModal = true")
          font-awesome-icon.mr-1(
            size="sm"
            icon="plus")
          span {{ 'actions.clone_recipe' | translate }}
    v-dialog(
      max-width="800"
      v-model="showCloneRecipeModal")
      create-recipe-modal(
        :duplicateItems="items"
        :ration="ration"
        @created="onRecipeCreate")
</template>

<script>
import CreateRecipeModal from './CreateRecipesModal'
import { percentReducer } from '@/util/utils'

export default {
  name: 'RecipeDetailModal',

  components: {
    CreateRecipeModal
  },
  props: {
    proportions: Array,
    ration: Object
  },

  mounted () {
    this.items = this.proportions.map(a => ({ ...a, percent: a.rate }))
  },

  data: () => ({
    items: [],
    showCloneRecipeModal: false
  }),

  computed: {
    totalPercent () {
      return this.items.reduce(percentReducer, 0)
    },

    isPercentValid () {
      return this.totalPercent === 100
    },

    isUserConsultant () {
      return this.$store.state.auth.user.user.role === 'Consultant'
    }
  },

  methods: {
    onSave () {
      this.$emit('close')
    },

    onChangePremix (ingredient, value) {
      this.items.forEach(item => {
        if (item.ingredient_id !== ingredient.ingredient_id) {
          item.premix = false
        }
      })
      ingredient.premix = value
    },

    onRecipeCreate (data) {
      data.created_at = new Date().toISOString()
      data.updated_at = new Date().toISOString()
      data.days_used = 1
      this.ration.active_recipes.push(data)
      this.showCreateRecipeModal = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
  .word-break {
    word-break: break-word;
  }
</style>
