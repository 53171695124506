<template lang="pug">
  .rations-table__row--bordered
    .rations-table__row.rations-table__row--body
      template(v-if="isUserConsultant")
        .rations-table__column.text-underline.cursor-pointer(@click="showDetail = true") {{ item.name }}
      template(v-else)
        .rations-table__column.text-underline.cursor-pointer(
          @click="showDetail = true" v-permission-disable="permissions.update_ration") {{ item.name }}
      .rations-table__column {{ item.created_at | date }}
      .rations-table__column {{ item.updated_at | date }}
      .rations-table__column
        span {{ item.days_used }}
        .rations-table__icon
          qr-code-dropdown.mr-1(
            position-right
            :name="item.name"
            :url="item.qr_code")
          v-switch.mr-1(
            :value="item.status"
            v-permission-hide="permissions.update_ration"
            @input="onStatusChange")
          v-btn(
            icon
            small
            @click="isToggled = !isToggled")
            v-chevron(
              :value="isToggled")
    template(v-if="isToggled")
      .rations-table__list-empty.rations-table__row--darken(
        v-if="!item.reports.length")
        | {{ 'messages.loading_list_empty' | translate }}
      template(v-else)
        .rations-table__header.rations-table__header--inner
          .rations-table__row.rations-table__row--header.rations-table__row--darken
            .rations-table__column {{ 'base.date' | translate }}
            .rations-table__column {{ 'base.weight' | translate }}
            .rations-table__column {{ 'base.start' | translate }}
            .rations-table__column {{ 'base.end' | translate }}
            .rations-table__column {{ 'base.time' | translate }}
            .rations-table__column {{ 'base.where_shipped' | translate }}
            .rations-table__column {{ 'base.mixer' | translate }}
        .rations-table__row.rations-table__row--bordered.rations-table__row--darken(
          v-for="(el, index) in item.reports"
          :key="index")
          .rations-table__column {{ el.planned_at | date }}
          .rations-table__column {{ el.plan_weight }}
          .rations-table__column {{ el.started_at | time }}
          .rations-table__column {{ el.ended_at | time }}
          .rations-table__column {{ el.time }}
          .rations-table__column {{ el.silages_list | liststr('number') }}
          .rations-table__column {{ el.mixer | liststr(null) }}
    v-dialog(
      max-width="700"
      v-model="showDetail")
      recipe-detail-modal(
        :proportions="item.proportions"
        :ration="ration"
        @close="showDetail = false")
</template>

<script>
import { mapActions } from 'vuex'
import QrCodeDropdown from '@/components/common/qr-code-dropdown/QrCodeDropdown'
import RecipeDetailModal from './modals/RecipeDetailModal'
import permissions from '@/util/permissions'

export default {
  name: 'RationsTableRowBodyItem',

  components: {
    QrCodeDropdown,
    RecipeDetailModal
  },

  props: {
    item: Object,
    ration: Object,
    active: Boolean
  },

  data: () => ({
    isToggled: false,
    showDetail: false,
    permissions: permissions
  }),

  computed: {
    isUserConsultant () {
      return this.$store.state.auth.user.user.role === 'Consultant'
    }
  },

  methods: {
    ...mapActions(['updateRecipe']),

    async onStatusChange (status) {
      const payload = {
        ...this.item,
        status
      }
      await this.updateRecipe(payload)
      if (!this.active) {
        this.ration.active_recipes.splice(this.ration.active_recipes.indexOf(this.item), 1)
        this.ration.archive_recipes.push(payload)
      } else {
        this.ration.archive_recipes.splice(this.ration.archive_recipes.indexOf(this.item), 1)
        this.ration.active_recipes.push(payload)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
