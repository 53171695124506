<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.recipe.create.title' | translate }}
    v-card-title
      .cols-x
        .col-x-2
          v-text-field(
            :label="$t('base.name')"
            v-model="form.name")
    .recipes-modal-table__wrapper
      recipes-modal-table(
        :list="items"
        :freezeList="freezeItems"
        :show-remove="totalIngredient > 1"
        @remove-item="removeItem"
        @list-oil="changeOil"
        @freeze-item-update="freezeItemUpdate")
      .recipe-table-controls
        .cols-x
          .col-x-2
            button(@click="addItem").btn.btn--link + {{ 'actions.add_ingredient' | translate }}
          .col-x-2
            .total-value(:class="{ '': !isPercentValid }") {{ totalPercent }}% *
        p.text-danger.mt-2(v-if="!isPercentValid") {{ 'messages.percent_valid' | translate }}
        p.text-danger.mt-2(v-if="!isAllIngredientsSelected") {{ 'messages.no_ingredients_selected' | translate }}
        p.text-danger.mt-2(v-if="!isPremixValid") {{ 'messages.premix_valid' | translate }}
        p.text-danger.mt-2(v-if="!isPremixBaseCorrect") {{ 'messages.premix_base_incorrect' | translate }}
        transition(name="fadex")
          p.text-danger.mt-2(
            v-if="!isDuplicateIngredient")
            | {{ !isDuplicateIngredient ? $t('messages.ingredients_duplicate') : null }}
    v-card-actions
      .cols-x
        .col-x-2
          v-text-field(
            :label="$t('base.note')"
            v-model="form.comment")
    v-divider
    v-card-actions
      v-btn(
        :disabled="!isPercentValid || !isAllIngredientsSelected || !isNameValid || !isDuplicateIngredient || !isAllPercentageSet || !isPremixValid || !isPremixBaseCorrect"
        :loading="loading"
        @click="onCreate"
      ) {{ 'actions.create' | translate }}

</template>

<script>
import RecipesModalTable from './RecipesModalTable'
import { percentReducer, ingredientPercentToFixedFloat } from '@/util/utils'

export default {
  name: 'CreateRecipesModal',

  props: {
    ration: Object,
    duplicateItems: Array
  },

  components: {
    RecipesModalTable
  },

  mounted () {
    if (this.duplicateItems !== undefined) {
      this.items = []
      this.duplicateItems.forEach(duplicateItem => {
        const newItem = {
          id: this.items.length,
          ingredient: duplicateItem.ingredient_id,
          premix: duplicateItem.premix,
          oil: duplicateItem.oil,
          order: duplicateItem.order,
          percent: duplicateItem.rate,
          autoload: duplicateItem.autoload
        }
        this.items.push(newItem)
      })
    }
  },

  data: () => ({
    form: {
      name: null,
      ration_id: null,
      comment: null
    },
    items: [
      {
        id: 0,
        ingredient: null,
        autoload: null,
        premix: false,
        oil: false,
        order: 0,
        percent: 0
      }
    ],
    freezeItems: [],
    loading: false
  }),

  methods: {
    removeItem (id) {
      this.items = this.items
        .filter(item => item.id !== id)

      this.freezeItems = this.freezeItems
        .filter(item => item.id !== id)
    },

    addItem () {
      const newItem = {
        id: this.items.length + this.freezeItems.length,
        ingredient: null,
        autoload: null,
        premix: false,
        oil: false,
        order: 0,
        percent: 0
      }
      this.items.push(newItem)
    },

    async onCreate () {
      this.loading = true
      let order = 0
      const payload = {
        name: this.form.name,
        proportions: [...this.items, ...this.freezeItems].map(item => {
          order++
          return {
            ingredient_id: item.ingredient,
            premix: item.premix,
            oil: item.oil,
            order: order,
            rate: ingredientPercentToFixedFloat(item)
          }
        }),
        comment: this.form.comment,
        ration_id: this.ration.id
      }
      const { data, error } = await this.$store.dispatch('createRecipe', payload)
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(this.$t(`errors.${key}.${msg}`), {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('created', data)
      }
    },

    changeOil (ingredient) {
      if (ingredient.oil || ingredient.premix) {
        this.items = this.items.filter(item => {
          return item.id !== ingredient.id
        })
        if (!this.freezeItems.includes(ingredient)) {
          this.freezeItems = [...this.freezeItems, ingredient].sort(function (x, y) { return y.premix - x.premix })
        }
      } else {
        const freezeItemsWithPremix = this.freezeItems.some(ingredient => ingredient.premix)
        this.freezeItems = this.freezeItems.filter(item => {
          if (!freezeItemsWithPremix && item.id !== ingredient.id) {
            item.oil = false
            this.items.push(item)
            return false
          }

          return item.id !== ingredient.id
        }).sort(function (x, y) { return y.premix - x.premix })
        this.items = [...this.items, ingredient]
      }
    },

    freezeItemUpdate () {
      this.freezeItems = this.freezeItems.filter(item => {
        const hasCheckboxes = item.oil || item.premix
        if (!hasCheckboxes) {
          this.items.push(item)
        }
        return hasCheckboxes
      }).sort(function (x, y) { return y.premix - x.premix })
    }
  },

  computed: {
    totalPercent () {
      return [...this.items, ...this.freezeItems].reduce(percentReducer, 0)
    },

    totalIngredient () {
      return this.items.length + this.freezeItems.length
    },

    isPercentValid () {
      return this.totalPercent === 100
    },

    isAllIngredientsSelected () {
      return ![...this.items, ...this.freezeItems].some(item => item.ingredient === null)
    },

    isAllPercentageSet () {
      return ![...this.items, ...this.freezeItems].some(item => {
        return +item.percent === 0
      })
    },

    isNameValid () {
      return this.form.name &&
        this.form.name.length
    },

    isDuplicateIngredient () {
      const ingredients = [...this.items, ...this.freezeItems].map(({ ingredient }) => ingredient)
      const uniqueIngredients = [...new Set(ingredients)]
      return ingredients.length === uniqueIngredients.length
        ? !!ingredients
        : !ingredients
    },

    isPremixValid () {
      const isPremixChecked = this.freezeItems.some(ingredient => {
        return ingredient.premix
      })

      if (!isPremixChecked) {
        return true
      }
      return this.freezeItems.some(ingredient => {
        return ingredient.oil
      })
    },

    isPremixBaseCorrect () {
      const premixBase = this.freezeItems.filter(ingredient => ingredient.premix === true)
      const compositePremixes = this.freezeItems.filter(ingredient => ingredient.oil === true)
      if (!premixBase.length && !compositePremixes.length) {
        return true
      }

      let compositePremixesPercent = 0
      compositePremixes.forEach(ingredient => { compositePremixesPercent += parseFloat(ingredient.percent) })

      return premixBase[0].percent >= compositePremixesPercent
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .total-value {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.8rem;
    margin-right: 8.7rem;
  }

  .recipes-modal-table__wrapper {
    padding: 3rem 2.5rem 2rem;
  }

  .recipe-table-controls {
    padding: 1rem 1.5rem 1.5rem;
    background-color: $color-gray-light-3;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: 1px solid $color-gray-light-2;

    p {
      font-size: 1.3rem;
    }
  }
</style>
